<template>
  <div class="py-sm-15 pt-8 pt-sm-10 py-sm-10 mt-5">
    <div class="row">
      <v-col cols="12" sm="12" md="9" lg="7" align="center" class="mx-auto">
        <v-card class="v-card--no-border primary--text" color="transparent">
          <h1
            class="font-weight-bold primary--text mb-8 mb-sm-15 pb-sm-5 text-center"
            style="max-width: 500px; margin-left: auto; margin-right: auto"
          >
            {{ $t('loginWelcome') }}
          </h1>
        </v-card>

        <ModalWindow :is-open="confirmDepositModal" success>
          <template #content>
            <p class="text-base">{{ $t('uploadBavConfirm') }}</p>
          </template>
          <template #actions>
            <v-btn color="primary">
              {{ $t('buttonConfirm') }}
            </v-btn>
          </template>
        </ModalWindow>
        <ModalWindow :is-open="submitWaiverModal" :title="$t('submitWaiverMessage')" :maxWidth="650">
          <template #actions>
            <v-btn outlined color="primary" @click="submitWaiverModal = false">
              {{ $t('buttonCancel') }}
            </v-btn>
            <v-btn color="primary" @click="submitWaiver">
              {{ $t('yes') }}
            </v-btn>
          </template>
        </ModalWindow>

        <ModalWindow :is-open="confirmWaiverModal" success>
          <template #content>
            <p class="text-base" v-html="$t('submitWaiverConfirm')"></p>
          </template>
          <template #actions>
            <v-btn color="primary" @click="$router.push('/meine-bav?direct=false')">
              {{ $t('buttonFinish') }}
            </v-btn>
          </template>
        </ModalWindow>
      </v-col>
      <v-col cols="12" sm="12" md="12" :lg="contractStatusExist ? '8' : '10'" class="mx-auto align-center">
        <small-tiles class="mb-5"></small-tiles>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="7" class="mx-auto">
        <v-card color="transparent">
          <p
            v-if="contractStatus === 'CONTRACT_ONBOARDED'"
            class="text-center text-body-2 text-sm-body-1 mb-3 mt-5 mt-sm-15"
          >
            {{ $t('waiverText') }}
          </p>

          <p v-if="contractStatus === 'CONTRACT_ONBOARDED'" class="text-center mb-sm-15">
            <v-btn
              color="primary"
              dark
              plain
              style="text-transform: none; text-decoration: underline"
              class="pl-3 pr-0 pl-sm-10 pr-sm-10 v-btn--contract"
              @click="submitWaiverModal = true"
            >
              {{ $t('waiverLink2') }}
            </v-btn>
          </p>
        </v-card>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mdiRocketLaunch, mdiArrowRight, mdiPlay, mdiPause, mdiRefresh, mdiFullscreen, mdiMenu } from '@mdi/js';
import { ref, getCurrentInstance, onMounted, computed } from '@vue/composition-api';
import { getEmployeeById, employeeOptOut, getContractByEmployeeId } from '@/api/employee';
import VimeoPlayer from '@/components/vimeo-player/VimeoPlayer.vue';
import ModalWindow from '@/components/modal/ModalWindow.vue';
import SmallTiles from '@/components/tiles/SmallTiles.vue';

export default {
  components: { VimeoPlayer, ModalWindow, SmallTiles },
  data() {
    return {
      duration: 300,
      offset: 20,
      easing: 'easeInOutCubic',
      playing: false,
      icons: {
        mdiRocketLaunch,
        mdiArrowRight,
        mdiPlay,
        mdiPause,
        mdiRefresh,
        mdiFullscreen,
        mdiMenu,
      },
    };
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const toggleExclusive = ref(undefined);
    const submitWaiverModal = ref(false);
    const confirmBavModal = ref(false);
    const confirmDepositModal = ref(false);
    const confirmWaiverModal = ref(false);
    const existingContractFile = ref(null);
    const { userData } = vm.$store.getters;
    const contractStatus = computed(() => vm.$store.state.auth.contract.contractStatus);
    const contractStatusExist = computed(() => {
      return contractStatus.value !== 'CONTRACT_ONBOARDED' && contractStatus.value !== 'CONTRACT_OPTED_OUT';
    });
    const userDetails = ref(null);
    const getDetails = (id) => {
      getEmployeeById(id).then((res) => {
        userDetails.value = res.data;
        getContractByEmployeeId(id).then((contract) => {
          vm.$store.commit('setContract', contract);
        });
      });
    };

    const submitWaiver = () => {
      employeeOptOut(userData.id, { emailPersonal: userDetails.value.personalDetails.personalEmail }).then(() => {
        submitWaiverModal.value = false;
        confirmWaiverModal.value = true;
      });
    };
    onMounted(() => {
      getDetails(userData.id);
    });
    return {
      contractStatusExist,
      toggleExclusive,
      submitWaiverModal,
      confirmBavModal,
      confirmDepositModal,
      existingContractFile,
      contractStatus,
      submitWaiver,
      getDetails,
      userData,
      confirmWaiverModal,
    };
  },
};
</script>
<style lang="scss">
@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-btn--contract {
    .v-btn__content {
      font-size: 12px !important;
    }
  }
}
</style>
