<template>
  <div class="d-flex justify-space-between flex-column flex-md-row flex-grow-0">
    <div class="d-md-flex" style="min-width: 270px">
      <v-card
        color="text-center"
        class="v-card--small-tile mb-5 mb-md-0"
        tansition="scale-transition"
        :to="{ name: 'bav-info' }"
      >
        <div class="mx-auto pt-6 pb-5">
          <v-avatar color="#1895F9" size="108">
            <v-img
              src="@/assets/images/tiles/bav_shield.svg"
              class="mx-auto overflow-visible"
              height="auto"
              :max-width="$vuetify.breakpoint.mdAndUp ? '100px' : '100px'"
            ></v-img>
          </v-avatar>
        </div>
        <v-card-text class="d-flex flex-column justify-center align-center">
          <h6 class="text-body-1 font-weight-medium">
            {{ $t('bavOption1') }}
          </h6>
        </v-card-text>
      </v-card>
    </div>

    <div class="d-md-flex" style="min-width: 270px">
      <v-card
        color="text-center"
        class="v-card--small-tile mb-5 mb-md-0"
        :disabled="!acceptedNecessary"
        @click="bookingBtnPressed"
      >
        <div class="mx-auto pt-6 pb-5">
          <v-avatar color="#1895F9" size="108">
            <v-img
              src="@/assets/images/tiles/heart_hands.svg"
              class="mx-auto overflow-visible"
              height="auto"
              :max-width="$vuetify.breakpoint.mdAndUp ? '70px' : '70px'"
            ></v-img>
          </v-avatar>
        </div>
        <v-card-text class="d-flex flex-column justify-center align-center">
          <h6 class="text-body-1 font-weight-medium primary-text">
            {{ $t('bavOption2') }}
          </h6>
        </v-card-text>
      </v-card>

      <!-- <v-card>
        <div class="mx-aut py-sm-10 py5">
          <v-img
            src="@/assets/images/tiles/heart_hands.svg"
            class="mx-auto"
            :class="
              $vuetify.breakpoint.mdAndUp ? 'rounded-lg' : 'rounded-0'
            "
            height="100%"
            :max-width="$vuetify.breakpoint.mdAndUp ? '35%' : '35%'"
          ></v-img>
        </div>

        <v-card-actions class="mx-auto">
          <v-btn class="mx-auto" color="primary" text large>
          {{$t('bavOption2')}}
          </v-btn>
        </v-card-actions>
      </v-card> -->
    </div>
    <div class="d-md-flex" style="min-width: 270px">
      <v-card
        color="text-center"
        :class="{ 'mb-5 mb-md-0': !contractStatusExist }"
        class="v-card--small-tile"
        to="meine-bav?direct=false"
      >
        <div class="mx-auto pt-6 pb-5">
          <v-avatar color="#1895F9" size="108">
            <v-img
              src="@/assets/images/tiles/home_page_tile_request_offer.svg"
              class="mx-auto overflow-visible"
              height="auto"
              :max-width="$vuetify.breakpoint.mdAndUp ? '42px' : '42px'"
            ></v-img>
          </v-avatar>
        </div>
        <v-card-text class="d-flex flex-column justify-center align-center">
          <h6 class="text-body-1 font-weight-medium">
            {{ contractStatusExist ? $t('bavOption3Onboarded') : $t('stepperButtonContinue4') }}
          </h6>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="!contractStatusExist" class="d-md-flex" style="min-width: 270px">
      <v-card color="text-center" class="v-card--small-tile" to="meine-bav?direct=true">
        <div class="mx-auto pt-6 pb-5">
          <v-avatar color="#1895F9" size="108">
            <v-img
              src="@/assets/images/tiles/home_page_tile_sign_bav.svg"
              class="mx-auto overflow-visible"
              height="auto"
              :max-width="$vuetify.breakpoint.mdAndUp ? '50px' : '50px'"
            ></v-img>
          </v-avatar>
        </div>
        <v-card-text class="d-flex flex-column justify-center align-center">
          <h6 class="text-body-1 font-weight-medium">
            {{ $t('setupBavDirectly') }}
          </h6>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, watch, computed } from '@vue/composition-api';
import { CONTRACT_ONBOARDED, CONTRACT_EXISTING, CONTRACT_OPTED_OUT, CONTRACT_OFFER_REQUESTED } from '../../constants';

export default {
  setup() {
    const vm = getCurrentInstance().proxy;
    const acceptedNecessary = ref(vm.$store.getters.necessaryCookies);
    const contractStatus = computed(() => vm.$store.state.auth.contract.contractStatus);
    const videoBookingModal = true;
    const bookingBtnPressed = () => {
      vm.$store.commit('bookConsultation/setVideoBooking', videoBookingModal);
    };
    watch(
      () => vm.$store.getters.necessaryCookies,

      () => {
        if (vm.$store.getters.necessaryCookies) {
          acceptedNecessary.value = true;
        } else {
          acceptedNecessary.value = false;
        }
      },
    );

    const contractStatusExist = computed(() => {
      return contractStatus.value !== CONTRACT_ONBOARDED && contractStatus.value !== CONTRACT_OPTED_OUT;
    });

    return {
      contractStatusExist,
      contractStatus,
      videoBookingModal,
      acceptedNecessary,
      bookingBtnPressed,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-application .v-card--small-tile:not(.v-card .v-card) {
  max-width: 230px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1) !important;
  background: #ecf1f9;
  border: rgb(70 89 193 / 100%) !important;
  transition: all ease-in-out 0.3s;
  flex: 1 1 auto;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    &:hover {
      transform: scale(1.1);
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    max-width: none;
  }
}

.v-card__text h6 {
  max-width: 200px;
}
</style>
