<template>
  <div>
    <v-card class="vimeo">
      <div id="vimeo" class="v-card__vimeo v-responsive__sizer" style="z-index: 1000; position: relative">
        <iframe
          v-if="$i18n.locale === 'en'"
          id="iframe"
          :src="`${videoEN}` + '&amp;app_id=122963&amp;controls=true'"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen=""
          title="P1 Welcomevideo (Portal)"
          data-ready="true"
          style="width: 100%"
        ></iframe>
        <iframe
          v-else
          id="iframe"
          :src="`${videoDE}` + '&amp;app_id=122963&amp;controls=true'"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen=""
          title="P1 Welcomevideo (Portal)"
          data-ready="true"
          style="width: 100%"
        ></iframe>
      </div>
    </v-card>
    <!--<div class="d-flex vimeo__controls mb-15">-->
    <!--<v-btn-toggle v-model="toggleExclusive">-->
    <!--<v-btn-->
    <!--depressed-->
    <!--@click.prevent="play"-->
    <!--active-->
    <!--&gt;-->
    <!--<v-icon color="primary">{{ playing === false ? icons.mdiPlay: icons.mdiPause }}</v-icon>-->
    <!--</v-btn>-->
    <!--<v-btn-->
    <!--depressed-->
    <!--@click.prevent="reload"-->
    <!--&gt;-->
    <!--<v-icon color="primary">{{ icons.mdiRefresh-->
    <!--}}</v-icon>-->
    <!--</v-btn>-->
    <!--<v-btn-->
    <!--depressed-->
    <!--@click.prevent="fullscreen"-->
    <!--&gt;-->
    <!--<v-icon color="primary">{{ icons.mdiFullscreen }}</v-icon>-->
    <!--</v-btn>-->
    <!--&lt;!&ndash; <v-btn depressed  @click="fullscreen">-->
    <!--<v-icon color="primary">{{ icons.mdiMenu }}</v-icon>-->
    <!--</v-btn> &ndash;&gt;-->

    <!--</v-btn-toggle>-->
    <!--</div>-->
  </div>
</template>

<script>
import { mdiArrowRight, mdiPlay, mdiPause, mdiRefresh, mdiFullscreen, mdiMenu } from '@mdi/js';
import { ref } from '@vue/composition-api';

export default {
  name: 'VimeoPlayer',
  data() {
    return {
      playing: false,
      icons: {
        mdiArrowRight,
        mdiPlay,
        mdiPause,
        mdiRefresh,
        mdiFullscreen,
        mdiMenu,
      },
    };
  },
  props: {
    videoDE: {
      type: String,
      required: true,
      default: null,
    },
    videoEN: {
      type: String,
      required: true,
      default: null,
    },
  },
  setup() {
    const toggleExclusive = ref(undefined);
    return { toggleExclusive };
  },
  created() {
    const externalScript = document.createElement('script');
    externalScript.setAttribute('src', 'https://player.vimeo.com/api/player.js');
    document.head.appendChild(externalScript);
  },
  mounted() {
    const iframe = document.querySelector('iframe');

    setTimeout(() => {
      this.player = new Vimeo.Player(iframe);
    }, 1000);
  },

  methods: {
    pause() {
      this.player.pause();
    },
    play() {
      this.playing === true ? this.player.pause() : this.player.play();
      this.playing = !this.playing;
    },
    reload() {
      this.playing = !this.playing;
      this.player.unload();
      this.player.play();
      this.playing = true;
    },
    fullscreen() {
      this.player.requestFullscreen();
    },
  },
};
</script>

<style lang="scss" scoped>
.vimeo .v-responsive__sizer {
  padding-bottom: 56%;
  z-index: 1 !important;
}
.vimeo {
  &:hover {
    cursor: pointer;
  }
}
.vimeo__controls {
  justify-content: center;
  .v-btn-toggle button {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: 0 !important;
  }
}
.v-card__vimeo {
  height: 100%;
  iframe {
    position: absolute;
    top: 0;
    height: 100%;
  }
}
</style>
